import { Component, OnInit, HostListener, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import 'owl.carousel';
import { PrivateService } from 'src/app/common/services/private/private.service';
declare var $: any;
@Component({
  selector: 'app-film',
  templateUrl: './film.component.html',
  styleUrls: ['./film.component.scss']
})
export class FilmComponent implements OnInit, OnDestroy {
  isVideo = false;
  isInMobileScreen = false;
  @Output() onClose = new EventEmitter<any>();
  film = {
    source: '',
    title: ''
  };
  constructor(
    private _router: Router,
    private _service: PrivateService,
    private titleService: Title
  ) { }
  
  ngOnInit(): void {
    this.onResize();
    this.getLastestVideo();
    this.isVideo = false;
  }
  ngOnDestroy() {
    this.isVideo = false;
  }

  onExit() {
    // $('.bar1').addClass('quit');
    // $('.bar2').addClass('quit');
    // setTimeout(() => {
    //   this._router.navigate(['/']);
    // }, 300)
    this.onClose.emit(true);
    this.titleService.setTitle('Home | Next Now');
    this.isVideo = false;
    if(this._router.url.indexOf('/film/page') > -1){
      this._router.navigate(['/']);
    }
  }

  getLastestVideo() {
    this._service.getLastestVideo().subscribe(data => {
      this.film.source = data.data.source;
      this.film.title = data.data.title;
    }, error => {
      
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isInMobileScreen = $(document).width() > 660 ? false : true;
  }
}
